import { isEqual } from "lodash-es";
import { v4 as uuidv4 } from "uuid";
import { TZoneMode, ZONES_MODES } from "@app/analysis/state/analysisConfiguration.constants";
import {
    GATE_POSITION_VALIDATION_WARNING_TEXT,
    NO_SELECTED_INTERSECTION_TEXT,
} from "@app/analysis/zones/chooseZones/configurations/tmc/state/tmcChooseZones.constants";
import { INTERSECTION_GATES_LIST } from "@common/features/intersections/intersection.constants";
import {
    validateGateDirections,
    validateGateNamesUniqueness,
    validateGateRolesUniqueness,
} from "@common/features/intersections/intersection.helpers";
import type {
    IIntersection,
    IIntersectionZone,
    IIntersectionZoneGate,
} from "@common/features/intersections/intersection.types";
import type {
    IAnalysisIntersectionZone,
    IValidateIntersectionsResponse,
} from "@common/services/server/analysesApi.types";

export const makeGateObject = (role = "North") => {
    return {
        role,
        road: "",
        direction: undefined,
        oneWay: false,
        id: uuidv4(),
        line_geom: null,
    } as IIntersectionZoneGate;
};

export const generateDefaultIntersection = (indexNumber?: number) => {
    const gates = INTERSECTION_GATES_LIST.map(gate => makeGateObject(gate.role));

    let indexPostfix = "";

    if (indexNumber) {
        indexPostfix = indexNumber < 10 ? `0${indexNumber}` : `${indexNumber}`;
    }

    return {
        zone_name: `Intersection ${indexPostfix}`.trim(),
        gates,
        id: uuidv4(),
        selectedGate: null,
    };
};

export const getIntersectionZonesFromAnalysis = (
    intersectionZones: Array<IIntersectionZone | IAnalysisIntersectionZone>,
) => {
    if (!intersectionZones)
        return {
            intersectionZones: {},
            intersectionZoneIdsList: [],
        };

    return intersectionZones.reduce(
        (
            res: {
                intersectionZones: Record<IIntersection["id"], IIntersection>;
                intersectionZoneIdsList: IIntersection["id"][];
            },
            intersection,
        ) => {
            const id = uuidv4();
            const gates: IIntersectionZoneGate[] =
                intersection.gates?.map(gate => ({
                    ...gate,
                    id: uuidv4(),
                    role: gate.role,
                    oneWay: !!gate.direction,
                    // TODO: Check why all types refer direction in TitleCase, while here we convert to lowercase
                    direction: (gate.direction
                        ? gate.direction.toLowerCase()
                        : undefined) as IIntersectionZoneGate["direction"],
                })) || [];

            const parsedIntersection: IIntersection = {
                id,
                zone_id: intersection.zone_id,
                zone_name: intersection.name,
                gates,
                selectedGate: null,
                point_geometry: intersection.point_geometry,
            };

            return {
                intersectionZones: {
                    ...res.intersectionZones,
                    [id]: parsedIntersection,
                },
                intersectionZoneIdsList: [...res.intersectionZoneIdsList, id],
            };
        },
        {
            intersectionZones: {},
            intersectionZoneIdsList: [],
        },
    );
};

// reused intersection object has only 'zone_id' property
export const getIsReusedIntersection = (intersection: IIntersection | IAnalysisIntersectionZone) =>
    isEqual(Object.keys(intersection), ["zone_id"]);

export const validateIntersections = (
    intersections: Array<IIntersectionZone>,
    zonesMode: TZoneMode,
) => {
    const hasImportedIntersections = intersections.every(intersection => !!intersection.zone_id);

    if (
        !intersections.length ||
        (zonesMode === ZONES_MODES.ZONE_SETS && !hasImportedIntersections)
    ) {
        return NO_SELECTED_INTERSECTION_TEXT;
    }

    const errors = intersections.reduce((res, intersection) => {
        // skip validation for existing intersections
        if (intersection.zone_id) {
            return res;
        }
        const hasInvalidGates = intersection.gates.some(gate => {
            return !gate.line_geom || !gate.role || !gate.road;
        });

        if (!intersection.name) {
            res.push("Intersection should have a name");
        }
        if (hasInvalidGates) {
            res.push(
                "Intersection should have a minimum of three gates, each with road, name and geometry",
            );
        }
        if (!validateGateRolesUniqueness(intersection.gates)) {
            res.push("Please provide unique roles for gates");
        }
        if (!validateGateNamesUniqueness(intersection.gates)) {
            res.push("Please provide unique street names for gates");
        }
        if (!validateGateDirections(intersection.gates)) {
            res.push("Gate directions should not be all inbound or all outbound traffic flow");
        }

        return res;
    }, [] as Array<string>);

    return errors.join(". ");
};

export const getGateRoadName = (gate: IIntersectionZoneGate) => `${gate.road}_${gate.role}`;

export const transformGatesForValidation = (gates?: Array<IIntersectionZoneGate>) => {
    return gates?.reduce(
        (gatesForValidation: Array<IIntersectionZoneGate & { road: string }>, gate) => {
            if (!gate.line_geom) return gatesForValidation;

            return [...gatesForValidation, { ...gate, road: getGateRoadName(gate) }];
        },
        [],
    );
};

export const transformIntersectionForValidation = (
    intersections: Partial<IIntersectionZone>[],
) => {
    return intersections.reduce((res: Partial<IAnalysisIntersectionZone>[], intersection) => {
        const gates = intersection.gates;

        if (!gates?.some(gate => gate.line_geom)) {
            return res;
        }

        res.push({
            ...intersection,
            gates: transformGatesForValidation(gates) as IAnalysisIntersectionZone["gates"],
        });

        return res;
    }, []);
};

export const getValidationGateWarning = (data: IValidateIntersectionsResponse["data"]) =>
    data.some(item =>
        (item.gate_warnings || []).some(gateWarning =>
            gateWarning.warning_msgs.includes(GATE_POSITION_VALIDATION_WARNING_TEXT),
        ),
    );
